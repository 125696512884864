import { render, staticRenderFns } from "./quizzes.vue?vue&type=template&id=6bdec507&scoped=true&"
import script from "./quizzes.vue?vue&type=script&lang=js&"
export * from "./quizzes.vue?vue&type=script&lang=js&"
import style0 from "./quizzes.vue?vue&type=style&index=0&id=6bdec507&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdec507",
  null
  
)

export default component.exports